<template>
  <div class="page">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <b-card>
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="name"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="role"
                    v-model="user.role"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="role"
                    readonly
                  />

                  <b-input-group-append>
                    <b-button
                      type="submit"
                      class="reqBtn"
                      variant="outline-primary"
                      @click="reqUpdate('Request role update', 'Account', '')"
                    >
                      Request Update
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="user.role === 'agent'"
              label="MLS Agent ID"
              label-for="mls-id"
            >
              <validation-provider
                #default="{ errors }"
                name="MLS ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="mls-id"
                    v-model="user.agent_mls_loginName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="mls-id"
                    readonly
                  />

                  <b-input-group-append>
                    <b-button
                      type="submit"
                      class="reqBtn"
                      variant="outline-primary"
                      @click="reqUpdate('Request agent id update', 'Account', 'change_agent_id')"
                    >
                      Request Update
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="user.role === 'agent'"
              label="Office Name"
              label-for="office-information"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Information"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-name"
                    v-model="office.officeName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-name"
                    readonly
                  />

                  <b-input-group-append>
                    <b-button
                      type="submit"
                      class="reqBtn"
                      variant="outline-primary"
                      @click="reqUpdate('Request office update', 'Account', 'change_office')"
                    >
                      Request Update
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="user.role === 'agent'"
              label="Office ID"
              label-for="office-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Office ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-id"
                    v-model="office.officeID"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-id"
                    readonly
                  />

                  <b-input-group-append>
                    <b-button
                      type="submit"
                      class="reqBtn"
                      variant="outline-primary"
                      @click="reqUpdate('Request office update', 'Account', 'change_office')"
                    >
                      Request Update
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    @input="formatPhoneNumber(user.phone)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                v-model="user.is_admin"
                value="1"
                class="custom-control-primary"
              >
                Is Admin
              </b-form-checkbox>
            </b-form-group>
            <div class="row">
              <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <b-button
                  v-b-modal.modal-1
                  variant="outline-danger"
                  class="mb-2 w-100"
                >
                  Delete User
                </b-button>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <b-button
                  variant="outline-primary"
                  class="mb-2 w-100"
                  :disabled="invalid"
                  @click="save"
                >
                  Save
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>

    <validation-observer
      ref="agentIdForm"
      #default="{invalid}"
    >
      <b-modal
        id="modal-1"
        title="Delete User"
        centered
        @ok="e => handleOk(e, invalid)"
      >
        <form
          @submit.stop.prevent="handleSubmit(invalid)"
        >

          <b-form-group
            label="Enter your Password to confirm"
            label-for="password"
            invalid-feedback="Password id required"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="confirmPassword"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility('password')"
                  />
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BFormGroup, BButton, BInputGroup, BFormInput, BFormCheckbox, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BInputGroup, BFormInput, BFormCheckbox, BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      roleOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'manager', text: 'Mananger' },
        { value: 'assistant', text: 'Assistant' },
      ],
      user: {
        unique_id: '',
        name: '',
        role: '',
        email: '',
        phone: '',
        is_admin: 0,
        agent_mls_loginName: '',
      },
      confirmPassword: '',
      office: {},
      officeOptions: [],
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const { id } = this.$route.params

    const data = {
      unique_id: id,
    }

    this.$store.dispatch('teams/getMemberDetail', data).then(response => {
      if (response.code === 200) {
        this.user.unique_id = response.data.unique_id
        this.user.email = response.data.email
        this.user.name = response.data.name
        this.user.role = response.data.role
        this.user.phone = response.data.phone
        this.user.is_admin = response.data.is_admin
        this.user.agent_mls_loginName = response.data.agent_mls_loginName
        this.office = response.data.office
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    save() {
      this.user.resource_office_id = this.office.officeID
      this.$store.dispatch('teams/updateMember', this.user).then(response => {
        if (response.code === 200) {
          this.showToast('User Updated', 'CheckCircleIcon', 'User has been updated successfully', 'success', 'bottom-right')
          if (response.data.unique_id === this.getClient.unique_id) {
            this.$store.commit('setClientInfo', response.data)
          }

          this.$router.push({ name: 'teams' })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    deleteUser() {
      const data = {
        unique_id: this.user.unique_id,
        password: this.confirmPassword,
      }
      this.$store.dispatch('teams/deleteUser', data).then(response => {
        if (response.code === 200) {
          this.$router.push({ name: 'teams' })
        } else {
          this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    handleOk(e, invalid) {
      e.preventDefault()
      this.handleSubmit(invalid)
    },
    handleSubmit(invalid) {
      if (!invalid) {
        this.deleteUser()
      }
    },
    formatPhoneNumber(data) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        this.user.phone = match[1] + '.' + match[2] + '.' + match[3]
      }
    },
    reqUpdate(tickettitle, cat, subCat) {
      this.$router.push({
        name: 'Create Ticket',
        params: {
          title: tickettitle,
          category: cat,
          subCategory: subCat,
        },
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
