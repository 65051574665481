var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-xs-12"},[_c('b-card',[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"role","state":errors.length > 0 ? false:null,"name":"role","readonly":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"reqBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request role update', 'Account', '')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.user.role === 'agent')?_c('b-form-group',{attrs:{"label":"MLS Agent ID","label-for":"mls-id"}},[_c('validation-provider',{attrs:{"name":"MLS ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"mls-id","state":errors.length > 0 ? false:null,"name":"mls-id","readonly":""},model:{value:(_vm.user.agent_mls_loginName),callback:function ($$v) {_vm.$set(_vm.user, "agent_mls_loginName", $$v)},expression:"user.agent_mls_loginName"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"reqBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request agent id update', 'Account', 'change_agent_id')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.user.role === 'agent')?_c('b-form-group',{attrs:{"label":"Office Name","label-for":"office-information"}},[_c('validation-provider',{attrs:{"name":"Office Information","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-name","state":errors.length > 0 ? false:null,"name":"office-name","readonly":""},model:{value:(_vm.office.officeName),callback:function ($$v) {_vm.$set(_vm.office, "officeName", $$v)},expression:"office.officeName"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"reqBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request office update', 'Account', 'change_office')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.user.role === 'agent')?_c('b-form-group',{attrs:{"label":"Office ID","label-for":"office-id"}},[_c('validation-provider',{attrs:{"name":"Office ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-id","state":errors.length > 0 ? false:null,"name":"office-id","readonly":""},model:{value:(_vm.office.officeID),callback:function ($$v) {_vm.$set(_vm.office, "officeID", $$v)},expression:"office.officeID"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"reqBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request office update', 'Account', 'change_office')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone"},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.user.phone)}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1"},model:{value:(_vm.user.is_admin),callback:function ($$v) {_vm.$set(_vm.user, "is_admin", $$v)},expression:"user.is_admin"}},[_vm._v(" Is Admin ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"mb-2 w-100",attrs:{"variant":"outline-danger"}},[_vm._v(" Delete User ")])],1),_c('div',{staticClass:"col-lg-6 col-md-12 col-sm-12 col-xs-12"},[_c('b-button',{staticClass:"mb-2 w-100",attrs:{"variant":"outline-primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)])]}}])})],1)],1)]),_c('validation-observer',{ref:"agentIdForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"modal-1","title":"Delete User","centered":""},on:{"ok":function (e) { return _vm.handleOk(e, invalid); }}},[_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit(invalid)}}},[_c('b-form-group',{attrs:{"label":"Enter your Password to confirm","label-for":"password","invalid-feedback":"Password id required","state":!invalid}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('password')}}})],1)],1)]}}],null,true)})],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }